.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/* ============== CustomerTour ============== */

button.but-primary {
  background: #4f46e5;
  padding: 14px 25px;
  border-radius: 28px;
  color: #fff;
  font-size: 21px;
  border: none;
}

.box {
  padding: 20px;
  background: white;
  box-shadow: 1px 2px #ddd;
  color: black;
  margin-top: 50PX;
  height: 130px;

}

.box:hover {
  background-color: #111;
  color: white;
}

.box p {
  font-size: 30px;
  color: inherit;
  margin-bottom: 3px;
}

.active {
  background-color: #111;
  color: white;
}

.box h6 {
  font-size: 11px;
  font-weight: 500;
  color: inherit;
}

.table-space tbody tr {
  margin-top: 20px;
  background-color: white;
  border: none;
}

.table thead tr th {
  padding: 10px !important;
  font-size: 13px;
  color: #999;
}

.table-space tbody tr {
  margin-top: 20px;
  background-color: white;
  border: none;
  line-height: 37px;
  border-top: 10px solid #f1f4f9;
  position: relative;
}

.btn-container {
  position: absolute;
}

.btn-container {
  display: none;
}

tr:hover .btn-container {
  display: block;
}

.btn-container {
  position: absolute;
  display: none;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

tr:hover .btn-container {
  display: block;
  opacity: 1;
}

.btn-container button {
  margin-right: 5px;
}

.btn-container.first {
  left: 155px;
}

.btn-container.second {
  right: 0;
  top: 0;
}

button.but-primary_tbl {
  background: #4f46e5;
  padding: 1px 15px;
  border-radius: 18px;
  color: #fff;
  font-size: 14px;
  border: none;
}

.popup_backc {
  background-color: #4f46e5;
  padding: 20px;
}

.mod_bock {
  background-color: rgb(241 245 249);
}

.popup_title {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

button.btn.btn-light.border.rounded-pill {
  font-size: small;
  margin-top: -10px
}

.th_header {
  font-size: 15px !important;
  color: #000 !important;
  padding: 20px !important;
}

.tab_lepopup {
  margin-left: 70px;
  margin-right: 70px;

}

.td_body {
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
}

.tab_lebody {
  background-color: rgb(241 245 249);
  border: 1px solid #ddd;
  padding: 30px;
}





/* start */

.table-borderless>:not(caption)>*>* {
  border-bottom: 1px solid #ddd !important;
  padding: 18px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000;
  border-bottom-color: 2px solid blue !important;
}

.nav-tabs {
  border-bottom: none;
}

label.form-label {
  color: #000;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 500;
}

.back_color {
  background-color: #f1f4f9;
}

.icon_size {
  font-size: 35px;
}

.amou_nt {
  font-size: 18px !important;
  color: #000 !important;
  font-weight: 400;
}

.spaced-tbody {
  margin-top: 20px;
  margin-bottom: 20px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none;
}

.nav-tabs .nav-link:active,
.nav-tabs .nav-link:focus {
  border-bottom: 3px solid blue;
}

.nav-link:hover,
.nav-link:focus {
  color: #000;
}

.nav-link {
  color: #000;
}
.table>:not(caption)>*>* {
  padding: 1.5rem 0.5rem !important;
}
.Mich_ael{
  text-decoration: none;
}








/* Adda admin start */
.back_color{
  background-color: #BA1E1E;
}
.sidebar .navbar .navbar-nav .nav-link:hover, .sidebar .navbar .navbar-nav .nav-link.active {
  background: #af5a5a !important;

}
#bg-dropdown, .sidebar .navbar .navbar-nav .show {
  background:  #af5a5a !important;
}
.sidebar .navbar .navbar-nav .nav-link i {
  font-size: 25px !important;
}
.sidebar .navbar .navbar-nav .nav-link {
  padding: 14px 30px 14px 25px;
}
.custom-card h1 i {
  transition: all .2s ease-in-out;
}
.custom-card h1 {
  font-size: 3rem;
}
.custom-card:hover i {
  transform: rotate(-10deg) scale(1.8);
}
.table>:not(:last-child)>:last-child>* {
  font-size: 15px;
  color: #fff;
  border-bottom-color: currentColor;
  text-align: center;
}
.table>thead {
  background-color: #BA1E1E;
  border-bottom: 0;
  padding: 10px;
}
.table>:not(caption)>*>* {
  text-align: center;
}
.modal {
  background: #381f1f78 !important;
}
.image_box {
  width: 320px;
  margin: 0px auto;
}